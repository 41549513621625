import SessionsService from '@/services/whatsapp/sessions.service.js'
import BrowserCache from '@/services/browser-cache.js'
import getSymbolFromCurrency from 'currency-symbol-map'
import DateRange from '@/components/DateRange/DateRange.vue'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import { Subscriptions } from '@/classes/helpers/Subscriptions'
import getEnv from '@/util/env'

export default {
  name: 'SpentInbox',
  components: { DateRange },
  data () {
    return {
      currencySymbol: getSymbolFromCurrency(BrowserCache.getJson('user').currencyCode),
      sessions: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      total: 0,
      disabledPagination: true,
      options: {},
      globalControl: new GlobalControl('2023-01-01'),
      showFilters: false,
    }
  },
  computed: {
    params (nv) {
      return {
        ...this.options,
      }
    },
    headers () {
      return [
        {
          text: this.$t('Fecha'),
          value: 'created_at',
        },
        {
          text: this.$t('Conversaciones'),
          value: 'quantity',
        },
        {
          text: this.$t('Coste'),
          value: 'cost',
        },
        {
          text: this.$t('Balance'),
          value: 'balance',
        },

      ]
    },
    hasSessions () {
      return this.sessions ? this.sessions.length > 0 : false
    },
  },
  watch: {
    params: {
      handler () {
        this.setParamsAndGetData()
      },
      deep: true,
    },
  },
  created () {},
  mounted () {
  },
  methods: {
    exportCsv () {
      const url = getEnv('VUE_APP_API_URL') + 'api/whatsapp/sessions/current-totals-csv?' + this.getUrlParams()
      window.location.href = url
    },

    getUrlParams () {
      return new URLSearchParams(this.setFromParamsAndColumns()).toString()
    },

    setFromParamsAndColumns () {
      const user = JSON.parse(localStorage.getItem('user'))
      const data = {
        initDate: this.showFilters ? this.globalControl.initDate : null,
        endDate: this.showFilters ? this.globalControl.endDate : null,
        token: user.token,
      }
      return data
    },

    selectDates (dates) {
      this.globalControl.initDate = dates[0]
      this.globalControl.endDate = dates[1]
      this.setParamsAndGetData()
    },
    setParamsAndGetData () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        initDate: this.showFilters ? this.globalControl.initDate : null,
        endDate: this.showFilters ? this.globalControl.endDate : null,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
        ? 'asc'
        : 'desc'
      }
      this.getCurrentUserSessionTotal(params)
    },
    getCurrentUserSessionTotal (params) {
      this.disabledPagination = true
      Subscriptions.checkSubscription(
        Subscriptions.WHATSAPP,
        () => {
          SessionsService.getCurrentUserSessionTotal(params).then((sessions) => {
            this.sessions = sessions.data
            this.itemsPerPage = parseInt(sessions.per_page)
            this.page = sessions.current_page
            this.total = sessions.total

            this.disabledPagination = false
          })
        },
        () => {},
      )
    },
  },
}
